.questions-section {
  padding-top: 40px;
  padding-bottom: 40px;
  background: #f8f5ac !important;
  border-bottom-right-radius: 200px;
  font-family: "Helvetica";


  &__top {
    background: #06d6a0;
    padding-top: 10px;
    padding-bottom: 10px;
    p {
      //   font-size: 30px;
      //   line-height: 76px;
      font-size: 20px;
      color: #ffffff;
      font-weight: bold;
      padding-left: 4px !important;
      @media (max-width: 2440px) and (min-width:991.99px) {
        padding-left: 20px !important;
      }
    }
  }

  @media (max-width: 991.99px) {
    border-bottom-right-radius: 150px;

    &__top {
      p {
        //   font-size: 30px;
        //   line-height: 76px;
        padding: 4px;
        font-size: 14px;
      }
    }
  }
    &__img {
      @media (max-width: 991.99px) {
        width: 80% !important;
        
      }
    }
}

.question-card {
  //   border-radius: 200px;
  border-radius: 150px;

  // padding: 70px !important;
  padding-top: 45px !important;
  padding-left: 60px !important;
  padding-right: 60px !important;
  padding-bottom: 15px !important;
  background-color: #ffffff;
  

  border-bottom-left-radius: 0px !important;
  &__text {
    // font-size: 15px;
    // line-height: 23px;
    color: #123953;
    font-size: 13px;
    font-weight: 800;
    font-family: "Helvetica" !important;
  }
  &__question {
    // font-size: 43px;
    line-height: 1;
    font-size: 32px;
    color: #123953;
    font-weight: 600;
  }
  &__arrow{
    transform: rotate(180deg);
    cursor: pointer;
  }
  &__label {
    // font-size: 22px !important;
  
    font-size: 12px !important;
    cursor: pointer !important;
    color: #123953 !important;
    font-weight: bold !important;
    margin-left: 12px;
    padding: 4px !important;
    
  }
  &__check {
    border-radius: 8px;
    background-color: #e3e3e3;
    // margin-top: 22px !important;
    // margin-bottom: 1.4rem;
    margin: 18px 0;
    padding: 7px 10px;
    cursor: pointer;
  }
  .form-check-input {
    margin-top: 0px !important;
  }
  .form-check {
    display: flex !important;
    align-items: center !important;
    margin-left: 7px;
  }
  input[type="radio"] {
    width: 17px;
    height: 17px;
  }

  @media (max-width: 991.99px) {
    border-radius: 110px;
    padding: 50px !important;
    border-bottom-left-radius: 0px !important;
    &__text {
      font-size: 11px;
    }
    &__question {
      font-size: 21px;
    }
    &__label {
      font-size: 14px !important;
    }
  }

  @media (max-width: 767.99px) {
    // border-radius: 130px;
    // padding: 60px !important;
    // border-bottom-left-radius: 0px !important;
    // &__text {
    //   font-size: 11px;
    // }
    // &__question {
    //   font-size: 21px;
    // }
    // &__label {
    //   font-size: 14px !important;
    // }
  }

  @media (max-width: 991.99px) {
    padding: 45px !important;
    border-radius: 100px;
    border-bottom-left-radius: 0px !important;

    &__text {
      font-size: 10px;
    }
    &__question {
      font-size: 18px;
    }
    &__label {
      font-size: 12px !important;
    }
  }
}
