@font-face {
  src:
    url("https://candyfonts.com/wp-data/2019/04/06/51368/HelveticaNeueCondensedBlack.ttf") format("opentype"),
    url("https://candyfonts.com/wp-data/2019/04/06/51368/HelveticaNeueCondensedBlack.ttf") format("truetype");
}

@font-face {
  font-family: 'Helvetica Neue Condensed Black';
src:url('../../fonts/Helvetica/Helvetica\ Neue\ Condensed\ Black.ttf')  
}
.hero-contents {
  background-image: url("../img/main_bg.png") !important;
  background-size: 100% 100% !important;
  // text-align: left !important;
  // font-family: "Poppins", sans-serif !important;
  font-family: "Helvetica";
  // padding-left: 10rem;

  @media (max-width: 991.99px) {
    background-image: none !important;
    background: #f8f5ac !important;
  }

  &__text {


    padding-top: 30px !important;
    padding-bottom: 20px !important;

    @media screen and (max-width: 990px) {
      padding: 20px !important;
      border-radius: 0% !important;
    }

    @media screen and (max-width: 420px) {
      padding: 10px !important;
      padding-bottom: 8px !important;
      margin-bottom: 0 !important;
    }


    p {
      font-family: "Helvetica";
      margin-bottom: 0;

    }

    &-title {
      // text-align: end;
      letter-spacing: 1px;
      font-size: 24px;
      font-weight: 500 !important;

      @media (max-width: 375px) {
        font-size: 26px;
        text-align: center;
      }
    }

    &-smtitle {
      font-size: 26px;
      font-weight: 700;
      font-family: 'Helvetica Neue Condensed Black', Arial, sans-serif !important;


      @media (max-width: 470px) {
        font-size: 20px;
        text-align: center;
      }
    }

    &-bgtext {
      line-height: 40px;
      font-size: 20px;
      font-weight: bold;
      margin-left: 25.6%;


      // background-color: #06d6a0;
      @media (max-width: 991px) {
        font-size: 18px;
        line-height: 40px;
        margin-left: 10px !important;
        text-align: center !important;
      }
    }

    &-description {
      font-size: 14px;
      font-weight: 100 !important;
      letter-spacing: 0px;
      // font-weight: 50 !important;
      // font-family: "Poppins", sans-serif !important;
      font-family: "Helvetica";
      // padding: 12px 0 12px 0;

      @media (max-width: 375px) {
        font-size: 12px;
        padding: 2px;
      }
    }

    &-btn {
      width: 280px;
      height: 47px;
      padding: 28px;
      // height: 20px;
      border-radius: 15px;
      font-weight: 800 !important;
      font-size: 22px;
      background-color: #fb862b;
      border: none;

      img {
        padding: 10px;
      }

      @media (max-width: 300px) {
        width: 180px;
        height: 30px;
        padding: 20px;
        font-weight: 800 !important;
        font-size: 12px;
      }

    }

    &-subtext {
      font-weight: 400 !important;
      font-size: 15px;
      line-height: 33px;
      color: #000000;
      font-weight: bold;
      font-style: italic;
    }

    &-boldtext {
      font-weight: 600 !important;
    }

  }



  .smbg {
    background-color: #06d6a0;
    // width: 92.5%;

    @media (min-width:993px) and (max-width: 2860px) {
      width: 70.3% !important;
    }

  }

  .underline {
    text-decoration: underline;
  }


  .startimg {
    width: 320px !important;
    // position: absolute;
    position: relative;
    bottom: 2%;
    top: 55%;
    right: 60%;
    transform: rotate(12deg);
    // left: 35%;

    @media (max-width: 767px) {
      display: none !important;
    }

    @media (min-width: 768px) and (max-width: 991px) {

      position: relative !important;

      width: 320px !important;
      bottom: 2%;
      top: 0%;
      left: 40%;
      transform: rotate(12deg);
    }

    @media (min-width: 992px) and (max-width: 1024px) {

      position: absolute !important;
      width: 260px !important;
      bottom: 2%;
      top: inherit;

      right: 12%;
      transform: rotate(12deg);
    }

    @media (min-width: 1025px) and (max-width:1347px) {
      width: 280px !important;
      position: relative;
      // margin-right: 3%;
      // left: 0%;
      right: 60% !important;


    }
  }
}

.Secbottom {
  padding: 12px;

  @media (min-width: 1024px) and (max-width:1347px) {
    padding: 8px !important;
  }
}

.footerimg {
  height: 220px !important;
  margin: 10px;

}